<template>
    <div class="container mt-4" style="text-align: justify;width: 1042px;box-shadow: 0 2px 4px -1px rgb(0 0 0 / 25%);padding: 0 100px 0 100px;">
        <div v-if="show" class="row mb-5">
            <div class="col" style="text-align: right">
                <b-button variant="outline-primary" style="margin-left: 10px;" to="/nadir">Voltar</b-button>
                <!-- <b-button variant="outline-primary" @click="pdfPrint()" style="margin-left: 10px;">To word</b-button> -->
                <b-button variant="outline-primary" @click="toPdf()" style="margin-left: 10px;">To PDF</b-button>
            </div>
        </div>
        <hr>
        <!-- <itemInput code="item_1_3" id_curso="7SZtFBIZbevUna8nGfhm" titulo="1.3 Justificativa para oferta do curso" v-model="formulario_especifico.item_1_3" @valorEmitido="log"></itemInput> -->
        <tabelaInput code="item_5_2" id_curso="jgXRqapZ50nU536KCcdQ" titulo="5.2 Relação de laboratórios" v-model="formulario_especifico.item_5_2" @valorEmitido="log"></tabelaInput>
        

        <!-- <questionInput v-model="documento_completo.item_1_3"></questionInput> -->

        <div id="capa" class="eow" style="text-align: center; height: 90vh">
            <div>
                <h4>Plano de curso</h4><br>
            </div>
            
            <div>
                <h1>CURSO TÉCNICO EM {{nome_curso.toUpperCase()}}</h1><br><br><br><br><br><br>
                <h1>EIXO TECNOLÓGICO: GESTÃO E NEGÓCIOS</h1><br><br><br><br><br><br>
            </div>
            
            <div>
                <h4>SÃO PAULO</h4><br>
                <h4>2022</h4>
            </div>
            
        </div>

        <hr class="mb-5 mt-5">
         
        <hr class="mb-5 mt-5"> <!-- ******************************** -->

        <div class="row mb-5 mt-5" style="text-align: center">
            <h1>Proposta Pedagógica do Curso</h1>
            <hr>
        </div>

        <div class="row mb-5 mt-5">
            <h2>1. Apresentação e justificativa.</h2>
            <div class="row mb-5">
                <h4>1.1 Apresentação da Instituição:</h4>
                <div v-html="documento_completo.item_1_1" id="elem_item_1_1" class="row"></div>
            </div>
            <div class="row mb-5">
                <h4>1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço):</h4>
                <div v-html="documento_completo.item_1_2" id="elem_item_1_1" class="row"></div>
            </div>
        </div>
        
       <hr>
       {{documento_completo.item_1_1}}

        <hr>
        <h1>Array de elementos</h1>
        {{elem_array}}
        <!-- <div>
            <div v-for="item in elem_array" :key="item.id">
                
                <div v-if="getTagName(item) == 'P'">
                   <p>{{returnTextOfElem(item)}}</p>
                </div>

            </div>
        </div> -->
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import tabelaInput from '@/views/teste_nadir/components/tabelaInput.vue'


// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: { 
        // itemInput,
        tabelaInput
    },
    data() {
        return {
            downloadURL: '',
            teste: ',',
            responsavel: '',
            nome_curso: '',
            formulario_especifico: [],
            documento_completo: [],
            elem_array: [],
            show: true,
            cover_pdf: [
                    {
                        text: 'Plano de curso\n\n\n\n\n',
                        style: 'header',
                        alignment: 'center',
                        fontSize: 15
                    },
                    {
                        text: 'CURSO TÉCNICO EM',
                        style: 'header',
                        alignment: 'center',
                        fontSize: 25,
                        margin: [0, 20]
                    },
                    {
                        text: this.nome_curso,
                        style: 'header',
                        alignment: 'center',
                        fontSize: 10,
                    },
                    {
                        text: 'EIXO TECNOLÓGICO: GESTÃO E NEGÓCIOS\n\n\n\n\n\n\n\n\n\n',
                        style: 'header',
                        alignment: 'center',
                        fontSize: 25
                    },
                    {
                        text: 'SÃO PAULO',
                        style: 'header',
                        alignment: 'center',
                        fontSize: 15
                    },
                    {
                        text: '2022',
                        style: 'header',
                        alignment: 'center',
                        fontSize: 15
                    },
            ]
        }
    },

    methods:{
        toPdf(){
            // this.show = false
            // window.print();
            
            var docDefinition = {
                content: this.elem_array,
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true
                    },
                    subheader: {
                        fontSize: 15,
                        bold: true
                    }
                }
            };

            pdfMake.createPdf(docDefinition).open();
        },

        convertToPlain(elem) {
            console.log(document.getElementById(elem).innerText)
            
            return document.getElementById(elem).innerText
        },

        createPdfElements(richText){
            const richTexttoConvert = new DOMParser().parseFromString(richText, 'text/html');
            const arr = [...richTexttoConvert.body.childNodes].map(child => child.outerHTML || child.textContent);
            console.log(arr);
        },

        getTagName(stringElm){
            let htmlObject = document.createElement('div');
            htmlObject.innerHTML = stringElm;
            return htmlObject.children[0].tagName
        },

        returnTextOfElem(stringElm){
            let htmlObject = document.createElement('div');
            htmlObject.innerHTML = stringElm;
            return htmlObject.textContent
        },

        returnPdfParagraph(stringElm){
            let htmlObject = document.createElement('div');
            htmlObject.innerHTML = stringElm;
            htmlObject.children[0].tagName

            if(htmlObject.children[0].children.length != 0){
                if(htmlObject.children[0].children[0].tagName === 'STRONG'){
                    return [{text: htmlObject.textContent, bold: true, alignment: 'justify'}, {text: '_______Subtitulo', style: 'header', bold: true, alignment: 'justify', tocItem: true}]
                } else {
                    return {text: htmlObject.textContent, bold: true, alignment: 'justify'}
                }
            } else {
                return [{text: htmlObject.textContent, bold: false, alignment: 'justify'}, {text: htmlObject.textContent.slice(0,20), style: 'subheader', bold: true, alignment: 'justify', tocItem: true}]
            }
        },

        newHeading(text){
            return {text: text,style: 'header', bold: true, tocItem: true}
        },

        newP(text){
            return [{text: text, bold: false, alignment: 'justify'}, '\n\n']
        },

        newT(){
            return [{
                    style: 'tableExample',
                    color: '#444',
                    table: {
                        widths: [200, 'auto', 'auto'],
                        headerRows: 2,
                        // keepWithHeaderRows: 1,
                        body: [
                            [{text: 'Header with Colspan = 2', style: 'tableHeader', colSpan: 2, alignment: 'center'}, {}, {text: 'Header 3', style: 'tableHeader', alignment: 'center'}],
                            [{text: 'Header 1', style: 'tableHeader', alignment: 'center'}, {text: 'Header 2', style: 'tableHeader', alignment: 'center'}, {text: 'Header 3', style: 'tableHeader', alignment: 'center'}],
                            ['Sample value 1', 'Sample value 2', 'Sample value 3'],
                            [{rowSpan: 3, text: 'rowSpan set to 3\nLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor'}, 'Sample value 2', 'Sample value 3'],
                            ['', 'Sample value 2', 'Sample value 3'],
                            ['Sample value 1', 'Sample value 2', 'Sample value 3'],
                            ['Sample value 1', {colSpan: 2, rowSpan: 2, text: 'Both:\nrowSpan and colSpan\ncan be defined at the same time'}, ''],
                            ['Sample value 1', '', ''],
                        ]
                    }
                }]
            },

            log(value){
                console.log('Item atualizado', value)
                // Após o elemento concluir a inserção de dados no firebase, atualiza os dados
                this.atualizarDados()
            },

            atualizarDados(){
                // Requisitando dados específicos do curso
                db.collection("/nadir").doc('jgXRqapZ50nU536KCcdQ')
                .get()
                .then((doc) => {
                    console.log(doc.data())
                    this.formulario_especifico = doc.data().formulario_especifico
                    this.nome_curso = doc.data().nome_curso
                    this.responsavel = doc.data().responsavel

                    // Requisitando dados gerais do curso
                    db.collection("/nadir").doc('forms_geral')
                    .get()
                    .then((doc) => {
                        this.documento_completo = doc.data().documento_completo
                    }).catch((error) => {
                        console.log("Error getting documents: ", error);
                    });
                    
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
            }
        },

   
    mounted() {
            // Requisitando dados específicos do curso
            db.collection("/nadir").doc('7SZtFBIZbevUna8nGfhm')
            .get()
            .then((doc) => {
                console.log(doc.data())
                this.formulario_especifico = doc.data().formulario_especifico
                this.nome_curso = doc.data().nome_curso
                this.responsavel = doc.data().responsavel

                // Requisitando dados gerais do curso
                db.collection("/nadir").doc('forms_geral')
                .get()
                .then((doc) => {
                    this.documento_completo = doc.data().documento_completo
                    
                    // this.to_export_pdf.push({text: this.documento_completo.item_1_1, fontSize: 15 })

                    const docx = new DOMParser().parseFromString(this.documento_completo.item_1_1, 'text/html');
                    const arr = [...docx.body.childNodes].map(child => child.outerHTML || child.textContent);
                    console.log(arr);


                    //criando array do pdf
                    let table_content = {toc: {title: {text: 'INDEX', style: 'header'}}}
                    let texto_array = arr.map(x => this.returnPdfParagraph(x))
                    this.elem_array.push(this.cover_pdf)
                    this.elem_array.push(table_content)
                    this.elem_array.push(texto_array) 
                   
                    
                    this.elem_array.push(this.newHeading('1. Apresentação e justificativa.'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_3))
    
                    this.elem_array.push(this.newHeading('1.1 Apresentação da Instituição. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                    this.newT()
                    
                    this.elem_array.push(this.newHeading('1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço). '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_5_1))
                    
                    this.elem_array.push(this.newHeading('1.3 Justificativa para oferta do curso'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_5_2))
                    
                    this.elem_array.push(this.newHeading('1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade). '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_3))
                    
                    this.elem_array.push(this.newHeading('1.5. Objetivos do Curso: '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_3_1))
                    
                    this.elem_array.push(this.newHeading('1.5.1. Objetivo Geral. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_4_1))
                    
                    this.elem_array.push(this.newHeading('1.5.2. Objetivos Específicos. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_4_4))
                     
                    this.elem_array.push(this.newHeading('2. Formas de acesso.'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                     this.newT()
                    this.elem_array.push(this.newHeading('2.1. Pré-requisitos para acesso ao curso.'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                    
                    this.elem_array.push(this.newHeading('2.2 Formas de acesso ao curso. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                    
                    this.elem_array.push(this.newHeading('3. Perfil profissional de conclusão. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                     
                    this.elem_array.push(this.newHeading('3.1 Habilidades e competências desenvolvidas na formação.'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                    
                    this.elem_array.push(this.newHeading('4. Organização Curricular. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                    
                    this.elem_array.push(this.newHeading('4.1. Representação gráfica da organização curricular e do itinerário formativo. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                     
                    this.elem_array.push(this.newHeading('4.2. Apresentação das Unidades Curriculares.'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))

                    this.elem_array.push(this.newHeading('1. Apresentação e justificativa.'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_3))
    this.newT()
                    this.elem_array.push(this.newHeading('1.1 Apresentação da Instituição. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                    
                    
                    this.elem_array.push(this.newHeading('1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço). '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_5_1))
                    
                    this.elem_array.push(this.newHeading('1.3 Justificativa para oferta do curso'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_5_2))
                    
                    this.elem_array.push(this.newHeading('1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade). '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_3))
                    
                    this.elem_array.push(this.newHeading('1.5. Objetivos do Curso: '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_3_1))
                    
                    this.elem_array.push(this.newHeading('1.5.1. Objetivo Geral. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_4_1))

                    this.elem_array.push(this.newHeading('1. Apresentação e justificativa.'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_3))
    
                    this.elem_array.push(this.newHeading('1.1 Apresentação da Instituição. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_4))
                    
                    
                    this.elem_array.push(this.newHeading('1.2 Apresentação da unidade de ensino ofertante (Código e-MEC do endereço). '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_5_1))
                    
                    this.elem_array.push(this.newHeading('1.3 Justificativa para oferta do curso'))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_1_5_2))
                    
                    this.elem_array.push(this.newHeading('1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade). '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_3))
                    
                    this.elem_array.push(this.newHeading('1.5. Objetivos do Curso: '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_3_1))
                    
                    this.elem_array.push(this.newHeading('1.5.1. Objetivo Geral. '))
                    this.elem_array.push(this.newP(this.formulario_especifico.item_4_1))

                }).catch((error) => {
                    console.log("Error getting documents: ", error);
                });
                
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }
}
</script>

<style>
p{
    margin: unset !important;
}
</style>